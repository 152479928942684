import * as React from "react";
import { PageLayout } from "../components/global/layouts";
import { SiteMetadata } from "../components/global/shared";
import {
  ContactForm
} from "../components/page/contact";

const ContactPage = () => {
  return (
    <PageLayout>
      <SiteMetadata title="Contact" description="Daniel Martin" />
      <ContactForm />
    </PageLayout>
  );
};

export default ContactPage;
