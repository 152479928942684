import React, { useState } from "react";
import { DangerAlert, SuccessAlert } from "../../../components/global/alerts";

const ContactForm = () => {
  const [result, setResult] = useState(null);
  const [subscribing, setSubscribing] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  async function onSubmit(e) {
    e.preventDefault();
    setSubscribing(true);
    try {
      await window.fetch(
        `https://getform.io/f/dfccf361-1c9c-4414-a41c-5b7b79897737`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            firstname: firstName,
            lastname: lastName,
            email,
            message,
          }),
        }
      );
      await fetch(`/api/contact`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, email, message }),
      }).then((res) => {
        const { status } = res;
        status === 200 ? setResult("success") : setResult("error");
        setSubscribing(false);
      });
    } catch (err) {
      setSubscribing(null);
      setResult("error");
    }
  }

  return (
    <div className="bg-gray-100">
      <div className="space-y-16 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
        <div className="text-center">
          <div className="text-sm uppercase font-bold tracking-wider mb-1 text-blue-700">
            Ready to work together?
          </div>
          <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
            Let's Connect
          </h2>
          <h3 className="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 lg:w-2/3 mx-auto">
            Thank you for your interest in&nbsp;reaching&nbsp;out.
            <br /> I will get back to you as&nbsp;soon&nbsp;as&nbsp;possible
          </h3>
        </div>
        <div className="lg:w-2/3 mx-auto bg-white p-5 md:p-10 rounded-lg shadow-sm">
          {result === "success" ? (
            <SuccessAlert
              title={"Thank you for submitting"}
              description={"I will try to get back to you as soon as possible."}
            />
          ) : (
            <form onSubmit={onSubmit} className="space-y-6" autoComplete="off">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="space-y-1">
                  <label htmlFor="tk-contact-firstname" className="font-medium">
                    First Name
                  </label>
                  <input
                    autoComplete="off"
                    className="block border placeholder-gray-400 px-5 py-3 leading-6 w-full rounded border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    type="text"
                    id="tk-contact-firstname"
                    name="firstname"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="tk-contact-lastname" className="font-medium">
                    Last Name
                  </label>
                  <input
                    autoComplete="off"
                    className="block border placeholder-gray-400 px-5 py-3 leading-6 w-full rounded border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    type="text"
                    id="tk-contact-lastname"
                    name="lastname"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label htmlFor="tk-contact-email" className="font-medium">
                  Email
                </label>
                <input
                  autoComplete="off"
                  className="block border placeholder-gray-400 px-5 py-3 leading-6 w-full rounded border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  type="email"
                  id="tk-contact-email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label htmlFor="tk-contact-message" className="font-medium">
                  Message
                </label>
                <textarea
                  autoComplete="off"
                  className="block border border-gray-200 rounded placeholder-gray-400 px-5 py-3 w-full focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  id="tk-contact-message"
                  name="message"
                  rows="6"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button
                disabled={
                  subscribing !== null ||
                  firstName === "" ||
                  lastName === "" ||
                  message === "" ||
                  email === ""
                }
                type="submit"
                className={`inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-6 rounded 
              ${
                subscribing !== null ||
                firstName === "" ||
                lastName === "" ||
                message === "" ||
                email === ""
                  ? `border-gray-700 bg-gray-700 text-white hover:text-white 
              hover:bg-gray-800 hover:border-gray-800 
              focus:ring focus:ring-gray-500 focus:ring-opacity-50 
              active:bg-gray-700 active:border-gray-700`
                  : `border-blue-700 bg-blue-700 text-white hover:text-white 
              hover:bg-blue-800 hover:border-blue-800 
              focus:ring focus:ring-blue-500 focus:ring-opacity-50 
              active:bg-blue-700 active:border-blue-700`
              }`}
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  className="opacity-50 hi-solid hi-paper-airplane inline-block w-5 h-5"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
                <span>{subscribing ? `Submitting...` : `Submit`}</span>
              </button>
              {result === "error" && (
                <DangerAlert
                  title={`Sorry, something is not working.`}
                  description={`Please try again in a few minutes.`}
                />
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
