import React from "react";

const DangerAlert = ({ title, description }) => (
  <div className="p-4 md:p-5 rounded text-red-700 bg-red-100">
    <div className="flex items-center mb-3">
      <svg
        className="hi-solid hi-x-circle inline-block w-5 h-5 mr-3 flex-none text-red-500"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
        />
      </svg>
      <h3 className="font-semibold">{title}</h3>
    </div>
    <p className="ml-8">{description}</p>
  </div>
);

export default DangerAlert;
