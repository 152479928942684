import React from "react";

const SuccessAlert = ({ title, description }) => (
  <div className="p-4 md:p-5 rounded text-green-700 bg-green-100">
    <div className="flex items-center mb-2">
      <svg
        className="hi-solid hi-check-circle inline-block w-5 h-5 mr-3 flex-none text-green-500"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      <h3 className="font-semibold">{title}</h3>
    </div>
    <p className="ml-8">{description}</p>
  </div>
);

export default SuccessAlert;
